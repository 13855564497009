/*
INDOMAK ADMINISTRATOR
URL: indomak.co.id
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: Aug 7, 2023
UPDATE DATE: Aug 7, 2023
REVISION: 1
NAME: variable.scss
TYPE: SASS Cascading Style Sheet
DESCRIPTION: IndoMak | ...
PALETTE:  1. #075698 / rgb(7, 86, 152) (Dark Blue / Venice Blue)
          2. #088A9E / rgb(8, 138, 158) (Dark Cyan / Blue Chill)
          3. #A5CAAE / rgb(165, 202, 174) (Grayish Lime Green / Spring Rain)
*/

$color1: #075698;
$color2: #088A9E;
$color3: #A5CAAE;
// $colorText: #364250;
$colorText: #6D6D6D;
// $colorLabel: #667383;
$colorLabel: #8D8D8D;
$colorError: #CB4646;
$colorSuccess: #4A915B;
$spacing: 20px;
$spacing2: $spacing - 5;
$spacing3: $spacing2  - 5;
$spacing4: $spacing3  - 5;
$radius: 20px;
$radius2: $radius - 5;
$radius3: $radius2 - 5;
$radius4: $radius3 - 5;

%textover{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

%transition{
  transition: all 200ms ease-in-out !important;
  -moz-transition: all 200ms ease-in-out !important;
  -webkit-transition: all 200ms ease-in-out !important;
}